/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import venture from '../../../assets/images/venture.svg'
import africa from '../../../assets/images/africa.png'
import BIZCOMMUNITY from '../../../assets/images/BIZCOMMUNITY.svg'
import disrupt from '../../../assets/images/disrupt.png'

class Recognition extends React.Component {
  render() {
    return (
      <Container className="border-top spb-5 recognition">
        <Row>
          <Col lg="6">
            <h2>Recognition</h2>
          </Col>
          <Col lg="6">
            <div className="border-bottom pb-5 mb-5">
              <div class="recog-wrapper">
                <img src={venture} alt="Ventureburn" />
              </div>
              <p>
                "The five startups were selected from among 2,256 applicants"
              </p>
              <a href="https://ventureburn.com/2019/11/social-tech-startup-challenge/" className="styled__Link-lfEBUk iUBhrC " target="_blank">
                Read more
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </a>
            </div>
            <div className="border-bottom pb-5 mb-5">
              <div class="recog-wrapper">
                <img src={BIZCOMMUNITY} alt="Bizcommunity" />
              </div>
              <p>"These ideas have been launched into the real world, and some are already getting their first customers, validating their ideas in market"</p>
              <a href="https://www.bizcommunity.com/Article/196/844/198664.html" className="styled__Link-lfEBUk iUBhrC" target="_blank">
                Read more
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </a>
            </div>
            <div className="border-bottom pb-5 mb-5">
              <div class="recog-wrapper">
                <img src={disrupt} alt="Disrupt Africa" />
              </div>
              <p>
                "The central driving force in this challenge has been to stimulate meaningful, strategic growth and innovation in a key industry of our economy"
              </p>
              <a href="https://disrupt-africa.com/2019/12/sa-market-research-startup-ispani-wins-170k-at-social-tech-startup-challenge/" className="styled__Link-lfEBUk iUBhrC " target="_blank">
                Read more
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </a>
            </div>
            <div>
              <div class="recog-wrapper">
                <img src={africa} alt="Africa.com" />
              </div>
              <p>
                "Social Entrepreneurs are taken from idea to launch, with expertise, support and seed funding offered to the most promising and scalable businesses."
              </p>
              <a href="https://africa.com/top-social-tech-startups-to-battle-it-out-for-r5-million-seed-funding/" className="styled__Link-lfEBUk iUBhrC " target="_blank">
                Read more
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Recognition