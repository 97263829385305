/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { MediaComponent } from '../../MediaComponent/MediaComponent'
import { Link } from 'gatsby'
import styled from 'styled-components';
import Image from 'gatsby-image';
import VideoIframe from '../../VideoIframe/VideoIframe'

class Topimage extends React.Component {
  render() {
    return (
      <Container className="scontent-wrap">
        <Row className="sp-5">
          <Col lg="7">
            <a className="casestudycategory">
              Incubators &amp; Accelerators
            </a>
            <h1>
              Revitalising an African-centered social tech incubator program.
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <VideoIframe embedVideo='https://player.vimeo.com/video/451080053?autoplay=1&loop=1&autopause=0&muted=1' />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Topimage