/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { MediaComponent } from '../../MediaComponent/MediaComponent'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import VideoIframe from '../../VideoIframe/VideoIframe'
import Image from 'gatsby-image'

const Demoday = () => {
      const demodayImages = useStaticQuery(graphql`
      query DemodayImages {
        first: file(absolutePath: {regex: "/DETSAPitchtopleft.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 4512) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        second: file(absolutePath: {regex: "/DETSAPitchmiddle.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1783) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        third: file(absolutePath: {regex: "/DETSAPitchtopright.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 4512) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fourth: file(absolutePath: {regex: "/DETSAPitchbottomleft.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 3897) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fifth: file(absolutePath: {regex: "/DETSAPitchbottomright.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 3651) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)


    return (
      <Container className="spt-8">
        <Row>
          <Col className="offset-md-1 offset-lg-3 col-lg-6 col-md-10">
            <h2>Keeping stakeholders informed throughout the program journey.</h2>
            <p>We used the program software and video summaries to ensure all Diageo stakeholders were kept up to date on key milestones of the program to provide as much accountability as possible.</p>
          </Col>
        </Row>
        <Row className="spt-5">
          <Col>
            <VideoIframe embedVideo='https://player.vimeo.com/video/451773255?autoplay=1&loop=1&autopause=0&muted=1' />
          </Col>
        </Row>
        <Row  className="spb-8">
          <Col className="offset-md-1 offset-lg-3 col-lg-6 col-md-10">
            <h2>Demo Day.</h2>
            <p>18 months of hard work culminated on the final pitch day. 15 of the top startups were provided the opportunity to engage with over 40 venture capitalists while the final 5 startups hit the stage and pitched their hearts out for a share of the R5 million investment capital fund.</p>
            <a target="_blank" className="styled__Link-lfEBUk iUBhrC font-weight-normal" href="https://www.socialtechstartup.co.za/">
              Visit the website to see the results
              <svg
                className="styled__Arrow-fdeFVt fRPwvC"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="smallteam-stsc">
            <div><Image sizes={demodayImages.first.childImageSharp.sizes} /></div>
            <div><Image sizes={demodayImages.second.childImageSharp.sizes} /></div>
            <div><Image sizes={demodayImages.third.childImageSharp.sizes} /></div>
            <div><Image sizes={demodayImages.fourth.childImageSharp.sizes} /></div>
            <div><Image sizes={demodayImages.fifth.childImageSharp.sizes} /></div>
          </Col>
        </Row>
        <Row className="spb-5">
          <Col>
            <VideoIframe embedVideo='https://player.vimeo.com/video/451837237?autoplay=1&loop=1&autopause=0&muted=1' />
          </Col>
        </Row>
      </Container>
    )
  }


export default Demoday
