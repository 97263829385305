/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { MediaComponent } from '../../MediaComponent/MediaComponent';
import VideoIframe from '../../VideoIframe/VideoIframe'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const Contentwrap = () => {
      const caseImages = useStaticQuery(graphql`
      query CaseImages {
        first: file(absolutePath: {regex: "/DETSAtopleft.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        second: file(absolutePath: {regex: "/DETSAmiddle.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1783) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        third: file(absolutePath: {regex: "/DETSAtopright.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fourth: file(absolutePath: {regex: "/DETSAbottmleft.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fifth: file(absolutePath: {regex: "/DETSAbottmright.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        sixth: file(absolutePath: {regex: "/DETSA_platform.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="spb-8 border-top">
        <Row className="sp-5">
          <Col lg="6" md="10" className="offset-lg-3 offset-md-1">
            <h2>
              A software-based people led accelerator program.
            </h2>
            <p>The entire programme was run from end-to-end on an online cloud-based platform. By allowing the platform to handle the administrative load; like applications, giving access to resources, submitting updates, checking programme dates and event days, etc. programme managers were freed up to spend more time focusing on the entrepreneurs and their needs.</p>
          </Col>
        </Row>
        <Row>
          <Col className="smallteam-stsc">
            <div><Image sizes={caseImages.first.childImageSharp.sizes}/></div>
            <div><Image sizes={caseImages.second.childImageSharp.sizes}/></div>
            <div><Image sizes={caseImages.third.childImageSharp.sizes}/></div>
            <div><Image sizes={caseImages.fourth.childImageSharp.sizes}/></div>
            <div><Image sizes={caseImages.fifth.childImageSharp.sizes}/></div>
          </Col>
        </Row>
        <Row className="spt-5">
          <Col>
            <figure>
              <Image sizes={caseImages.sixth.childImageSharp.sizes}/>
              <figcaption>A screenshot of the software we used to run the entire program.</figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="spt-10">
          <Col lg="6" md="10" className="offset-md-1 offset-lg-3">
            <h2>
              We were hands-on and knee-deep taking 30 startups from validating
              business models to testing prototypes with users.
            </h2>
            <p>
              Through group workshops and one on one sessions we helped our
              cohort establish whether they have an actual product that people
              need. We went about this process by teaching all the participants
              how to test their assumptions using user research through applying
              design thinking principles, completing a business model canvas,
              doing revenue forecasts and building low-fidlity prototypes. After
              more sessions than we could count, we managed to ensure that each
              cohort member had a basic 1st version of their product ready for
              user testing. This included basic user flows and the best user
              experience possible for each prototype.
            </p>
          </Col>
        </Row>
        <Row className="spt-5">
          <Col>
            <VideoIframe embedVideo='https://player.vimeo.com/video/451836736?autoplay=1&loop=1&autopause=0&muted=1' />
          </Col>
        </Row>
      </Container>
    )
  }


export default Contentwrap
