import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { Waypoint } from 'react-waypoint'
import styled from 'styled-components'


export default class VideoIframe extends Component {
  state = {
    playing: false
  }
  
  render () {
    return (
      <Waypoint 
        onEnter={() => this.setState({ playing: true })}
        onLeave={() => this.setState({ playing: false })}
      >
        <WrapperIframe>
            <ContainerIframe>
                <ReactPlayer className="responsive-iframe" url={this.props.embedVideo} playing={this.state.playing} muted={this.state.playing} controls />
            </ContainerIframe>
        </WrapperIframe>
      </Waypoint>
    )
  }
}

export const ContainerIframe = styled.div`
position: relative;
overflow: hidden;
width: 100%;
padding-top: 56.25%;

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
`

export const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
  background-color: none !important;
`

export const WrapperIframe = styled.div``